<template>
  <div>
    <b-card title="Ajuste Negativo (-)">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="8">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="$hascan('inventario_inicial-registrar')"
                @click="$router.push('/ajuste-negativo/nuevo')"
              >
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVO AJUSTE</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="verPdf(item.id)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span class="align-middle">VER VER AJUSTE</span>
                </span>
                <feather-icon icon="EyeIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                v-if="$hascan('inventario_inicial-anular')"
                @click="confirmarEliminar(item)"
                :disabled="buttonDisabled || item.estado != 1"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">ANULAR</span>
                </span>
                <feather-icon icon="XIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" md="4" class="mt-1 mt-md-0">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="10" xl="6">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItem="selectItem"
        />
      </b-card-text>
    </b-card>
    <ModalPdfTransaccion
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      @cerrarModalPdf="cerrarModalPdf"
    />
  </div>
</template>
  
  <script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import AjusteServices from "../services/index";
import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    BPagination,
    ModalPdfTransaccion,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
      tipo_transaccion: "INVENTARIO NEGATIVO",
      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
    };
  },
  created() {},
  methods: {
    // SERVICES
    async listar() {
      this.loading = true;
      try {
        const response = await AjusteServices.get(
          this.tipo_transaccion,
          this.mixing.sede_id
        );
        this.items = response.data[0];
        this.item = [];
        this.buttonDisabled = true;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdf(id) {
      try {
        const response = await AjusteServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
    },
    async eliminar(id) {
      try {
        await AjusteServices.destroy(id);
        this.$bvToast.toast("Se anulo correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
        this.listar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.listar();
    },
    selectItem(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    confirmarEliminar(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea anular el ajuste negativo.", {
          title: "ANULAR AJUSTE NEGATIVO ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si, Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-dark",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.eliminar(item.id);
          }
        });
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
  },
  mounted() {
    this.listar();
  },
};
</script>
  
  <style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
  
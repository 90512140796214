import axiosIns from "@/libs/axios";

const get = async (tipo_transaccion,sede_id) => {
  return await axiosIns.get(`ajuste-negativo?tipo_transaccion=${tipo_transaccion}&sede_id=${sede_id}`)
}

const store = async (data) => {
  return await axiosIns.post('ajuste-negativo', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`ajuste-negativo/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`ajuste-negativo/${id}`)
}

const findOne = async (id) => {
  return await axiosIns.get(`ajuste-negativo/${id}`)
}

const findOnePdf = async (id) => {
  return await axiosIns.get(`ajuste-negativo/pdf/${id}`)
}
  
export default {
  get,
  store,
  update,
  destroy,
  findOnePdf,
  findOne
}
